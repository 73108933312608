<template>
  <div>
    <AdminLocationClosureForm
      :closure="closure"
      @submit="addClosure"
      :title="'Add New Park Closure'"
    />
  </div>
</template>

<script>
import AdminClosureService from "@/services/admin/AdminClosureService.js";
import AdminLocationClosureForm from "@/components/admin/AdminLocationClosureForm";

export default {
  name: "AdminLocationClosureAdd",
  title: "Admin - Location Closure Add",
  components: {
    AdminLocationClosureForm
  },
  data() {
    return {
      closure: {
        startDate: "",
        endDate: "",
        isRecurring: false,
        overrideAllowed: false,
        closureType: null,
        includeSunday: true,
        includeMonday: true,
        includeTuesday: true,
        includeWednesday: true,
        includeThursday: true,
        includeFriday: true,
        includeSaturday: true
      }
    };
  },
  methods: {
    addClosure(request) {
      let closureService = new AdminClosureService(this.tenantId);
      closureService?.addClosure(request).then(response => {
        if (response.statusCode == "Success") {
          this.$router.push("/admin/park-closure-search").catch(() => {});
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Park Closure Added Successfully",
            layer: "admin"
          });
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  }
};
</script>

<style></style>
